.no-pointer-events {
  pointer-events: unset !important;
}

.flatpickr-day.flatpickr-disabled {
  color: rgba(57,57,57,0.1) !important;
}

.flatpickr-day.today {
  background-color: white !important;
  border-color: white !important
}